@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Nunito Sans', sans-serif;
}

/* ===== Scrollbar Customization CSS ===== */
/* Firefox */
* {
    scrollbar-width: auto;
    scrollbar-color: #8f6a1b #ebebeb;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 8px;
}

*::-webkit-scrollbar-track {
    background: #ebebeb;
    border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
    background-color: #8f6a1b;
    border-radius: 10px;
    border: none;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: #8f6a1b;
}